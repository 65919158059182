<template>
  <ethnic-background-template
    :ethnicity="ethnicity"
    :other-ethnicity="otherEthnicity"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import EthnicBackgroundTemplate from '@/modules/questionnaire/components/steps/common/identity/ethnic-background/EthnicBackgroundTemplate.vue';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { includes } from '@/modules/questionnaire/api/helpers';

import { OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

const { requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [requiredArrayField('ethnicity'), requiredField('otherEthnicity')];

export default {
  name: 'EthnicBackground',
  components: {
    EthnicBackgroundTemplate
  },
  mixins: [makeStep(FIELDS)],
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'otherEthnicity') {
        return includes(this.ethnicity, OTHER_VALUE_BINDINGS.value);
      }

      return true;
    }
  }
};
</script>
